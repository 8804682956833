.progressbar-wrapper {
  height: 80px;
  display: flex;
  align-items: center;
  position: sticky;
  background-color: white;
  top: 0;
  /* opacity: 0.96; */
}

.progressbar {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
  display: flex;
  border: 5px solid #99e580;
  border-left: 0;
  border-right: 0;
  background-color: #99e580;
}

.progressbar-value {
  transition: width 1s;
  position: absolute;
  background: #532bbd;
  height: 10px;
  /* width: 0; */
  left: 0;
}
