:root {
  --color-primary-violet: #532bbd;
  --box-shadow-primary: 10px 10px 0px var(--color-primary-violet);
}

.tips,
.tips * {
  box-sizing: border-box;
}

.tip-rows-tablet {
  display: none;
}

.tip-rows-desktop {
  display: none;
  margin: 0 0 4rem 0;
}

@media screen and (min-width: 768px) {
  .tips-mobile {
    display: none;
  }

  .tip-rows-tablet {
    display: block;
    margin: 0 0 4rem 0;
  }
}
@media screen and (min-width: 1024px) {
  .tips-mobile {
    display: none;
  }

  .tip-rows-tablet {
    display: none;
  }

  .tip-rows-desktop {
    display: block;
  }
}

.tip-row {
  margin: 0 auto;
  position: relative;
}

.tip-row + .tip-row {
  margin-top: 3rem;
}

.tip-row-cards {
  display: flex;
}

.tip-row-cards .tip-card + .tip-card {
  margin: 0 0 0 3rem;
}

.tip-triangle {
  width: 68px;
  height: 21px;
  background-color: white;
  position: absolute;
  bottom: -45px;
  left: 40%;
}

.tip-triangle svg {
  fill: transparent;
  stroke: var(--color-primary-violet);
  stroke-width: 2;
}

.tip-row-details {
  margin: 2.5rem 0;
}

.tip-card,
.tip-details {
  padding: 20px 15px;
  border: 2px solid var(--color-primary-violet);
  border-radius: 6px;
}

.tip-card {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  background: #ffffff;
  box-shadow: var(--box-shadow-primary);
  z-index: 1;
}

.tip-card__active {
  box-shadow: none;
  /* transform: translateY(10px); */
}

.tip-card__placeholder {
  box-shadow: none;
  border: none;
}

.tip-card-status {
  position: absolute;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  right: 11px;
  top: 11px;
}

.tip-card-status svg {
  transform: scale(1.3);
}

.tip-card-status__green {
  background-color: #99e580;
}

.tip-card-status__yellow {
  background-color: #fbd13a;
}

.tip-card-status__red {
  background-color: #eb5757;
}

.tip-card-title {
  font-family: FatFrank;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--color-primary-violet);
  width: calc(100% - 2.5rem);
}

.tip-card-text {
  font-size: 16px;
  line-height: 30px;
  color: #1e1e1c;
  padding: 10px 0 20px 0;
}

.tip-card-expand {
  margin: auto 0 0 0;
  color: var(--color-primary-violet);
  display: flex;
  cursor: pointer;
}

.tip-card__placeholder .tip-card-expand {
  display: none;
}

.tip-card-chevron {
  margin: 0 0 0 1rem;
}

.tip-details {
  text-align: left;
  display: flex;
  position: relative;
}

.tip-detail-separator {
  width: 2px;
  height: auto;
  background-color: var(--color-primary-violet);
  margin: 0 3rem;
}

.tip-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.tip-detail-button {
  margin: auto 0 0 0;
}

/* Mobile */

.tips-mobile {
  margin: 3rem 0 5rem 0;
}

.tip-card-mobile {
  width: calc(100% - 1rem);
}

.tip-card-mobile + .tip-card-mobile {
  margin: 3.5rem 0 0 0;
}

.tip-card-mobile .tip-card-status {
  position: absolute;
  top: -20px;
  right: -20px;
  box-shadow: var(--box-shadow-primary);
  border: 2px solid white;
}

.tip-card__active .tip-card-status {
  box-shadow: none;
}

.tip-details-mobile {
  margin: 2rem 0;
}

.tip-details-mobile > div + div {
  margin: 4rem 0 0 0;
}
