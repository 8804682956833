.introduction-header h1 {
  padding: 60px 0 60px 0;
  margin: 0;
}
.introductioncard-container {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
  transform: translateY(50px);
  max-width: 960px;
  margin: 0 auto;
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  /* padding: 100px 0; */
}
.introductioncard {
  box-sizing: border-box;
  width: 255px;
  height: 160px;
  background-color: #ffffff;
  filter: drop-shadow(5px 5px 0px #532bbd);
  border-radius: 15px;
  overflow: hidden;
  /* margin: 20px auto 20px auto; */
  border: 2px solid #ffffff;
  border-radius: 6px;
}
.introductioncard-text {
  padding: 16px 16px 16px 16px;
  height: 100%;
  text-align: left;
  background-color: #fff;
}
.introductioncard-heading {
  font-family: FatFrank;
  font-size: 20px;
  color: #532bbd;
  font-weight: 900;
  text-transform: uppercase;
}
.introductioncard-description {
  font-size: 16px;
  margin-top: 10px;
  color: #1e1e1c;
}
