.navbar {
  height: 75px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  border-bottom: 2px solid #532bbd;
}

.navbar-content {
  box-sizing: border-box;
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 75px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.navbar-left {
  text-align: start;
  font-size: 12px;
  height: 100%;
}
.navbar-right {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  color: #99e580;
  height: 100%;
}
.navbar-link {
  padding: 0 1rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.navbar-right a:last-child .navbar-link {
  padding-right: 0;
}

.navbar-link::after {
  content: attr(data-text);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  font-weight: 900;
}
.navbar a {
  height: 100%;
  display: flex;
  width: fit-content;
}
.navbar-logo {
  margin: 10px 0 0 0;
}
.navbar-logo svg {
  box-sizing: border-box;
  width: 86px;
  height: 54px;
  display: flex;
}

/* .navbar-brandname {
  margin: auto 0;
  color: #532bbd;
  text-transform: uppercase;
  font-weight: 900;
  THIS IS FOR LINE BREAKS. CAN BE REMOVED WHEN LOGO ARRIVES
  word-spacing: normal;
  width: min-intrinsic;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  display: table-caption;
  display: -ms-grid;
  -ms-grid-columns: min-content;
} */
.navbar-link {
  color: #532bbd;
  margin: auto 0;
}

.navbar-hamburger {
  display: none;
}

@media screen and (max-width: 920px) {
  .navbar,
  .navbar-content {
    display: inline-block;
    align-items: center;
    height: 100%;
    box-shadow: none;
    padding: 0;
  }
  .navbar-left {
    align-items: center;
    width: 100%;
    padding: 0;
    display: flex;
    flex-wrap: row nowrap;
  }

  .navbar-left a {
    /* width: 100%; */
    /* align-items: center; */
    /* justify-content: center; */
    padding: 10px 0 10px 0;
    margin: 0 auto;
  }
  .navbar-right {
    display: none;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 0 0 6px 0;
    border-top: 2px #532bbd solid;
  }
  .navbar__open .navbar-right {
    display: flex;
  }
  .navbar-right a {
    /* margin: 0 auto; */
    /* text-align: left; */
    font-size: 24px;
    width: 90%;
    height: 100%;
    /* padding-left: 60px; */
    padding: 10px 0 10px 10%;
    overflow: none;
  }
  .navbar-hamburger {
    display: block;
    padding: 30px;
    position: absolute;
    /* width: 50px; */
  }
}
