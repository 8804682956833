.about {
  width: 100%;
  height: auto;
  margin-bottom: 200px;
}

.about-container {
  margin: 0 30%;
}
.about-title {
  font-size: 40px;
  width: 100%;
  background-color: rgba(242, 153, 74, 0.1);
  padding: 50px 0;
}
.about-description {
  font-size: 16px;
  font-weight: 700;
  padding: 30px 0;
  text-align: left;
}
.about-heading {
  font-size: 40px;
  text-align: left;
  padding: 20px 0;
}
.about-text {
  font-size: 16px;
  text-align: left;
  padding: 20px 0;
}
