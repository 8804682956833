.instruction {
  background-color: #99e580;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0 80px 0;
  text-align: left;
}
.instruction-wrapper {
  display: flex;
  padding: 0;
}
.instruction-text {
  max-width: 445px;
  margin: 0 5rem 0 0;
}
.instruction-button {
  margin: auto 0 0 0;
  min-width: 280px;
}
@media (max-width: 768px) {
  .instruction-wrapper {
    flex-direction: column;
  }
  .instruction-text {
    margin: 0;
  }
}
