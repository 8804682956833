.resultscard {
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
}
.resultscard-container {
  background-color: #532bbd;
  display: flex;
  height: auto;
  padding: 40px 60px 0 60px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #99e580;
  justify-content: center;
}
.resultscard-text {
}

.resultscard-title {
  font-size: 40px;
  color: #1d252c;
  font-family: FatFrank;
  color: #99e580;
}
.resultscard-score {
  font-family: FatFrank;
  font-style: normal;
  font-weight: 900;
  font-size: 100px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.resultscard-description {
  font-size: 18px;
  margin: 10px auto;
  color: #fff;
  width: 288px;
  font-family: FatFrank;
  font-weight: 900;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.resultscard-description span {
  background: #99e580;
  padding: 0 5px;
  border-radius: 20px;
  white-space: nowrap;
}
.resultscard-tips {
  max-width: 516px;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 20px 0 20px 0;
  text-transform: none;
  text-align: left;
}
.resultscard-goto {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
  justify-content: space-between;
}
.resultscard-call {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 24px;
  font-weight: 900;
  color: white;
  text-transform: none;
  margin-left: 10px;
}
.resultscard-phone {
  color: white;
  font-size: 48px;
  font-weight: 900;
}
