.cookies {
  width: 100%;
}
.cookie-content {
  padding-top: 55px;
  padding-bottom: 20px;
}
.cookie-window {
  background-color: #99e580;
}
.cookie-content .button {
  margin: 60px 0;
}

.cookie-text {
  max-width: 540px;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: left;
}
.cookie-topic {
  display: flex;
  margin: 30px 0 0 0;
}

.cookie-paragraph {
  margin: 0 0 0 20px;
}
.cookie-heading {
  color: #4f4f4f;
  font-family: FatFrank;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  /* line-height: 48px; */
}

.cookie-heading a {
  color: #532bbd;
  text-decoration: underline;
}
.cookie-heading.purple {
  color: #532bbd;
  font-family: FatFrank;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  /* line-height: 48px; */
}

.checkbox,
.checkbox * {
  box-sizing: border-box;
}

.checkbox {
  min-width: 32px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="checkbox"] {
  -webkit-appearance: initial;
  appearance: initial;
  border-radius: 4px;
  border: 2px solid #532bbd;
  min-width: 22px;
  width: 22px;
  height: 22px;
}

input[type="checkbox"]:checked {
  background-color: #532bbd;
}

.checkbox__disabled input[type="checkbox"][disabled] {
  border-color: #4f4f4f;
  opacity: 1;
}

.checkbox__disabled input[type="checkbox"] {
  background-color: #4f4f4f;
}

input[type="checkbox"]:checked::after {
  box-sizing: border-box;
  content: url(../../images/checkbox.svg);
  position: absolute;
  top: 7px;
  left: 7px;
  transform: scale(0.8);
}
