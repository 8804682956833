.brands,
.brands > * {
  box-sizing: border-box;
}

.brands {
  text-align: left;
  display: flex;
  align-items: center;
  padding: 120px 0;
}

.brands-about {
  max-width: 450px;
  margin: 0 0 0 80px;
}

.brands-images {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.brands-images section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.brands-images div + div {
  margin-top: 4rem;
}

.brands-images section > div {
  width: 152px;
  text-align: center;
}

.brands-images img {
  width: 100%;
}

.brands-images .brands-nibud {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .brands-about {
    margin: 0;
  }
}

@media screen and (max-width: 880px) {
  .brands {
    flex-direction: column;
    padding: 40px 0 70px;
  }

  .brands .brands-images {
    margin: 4rem 0 0 0;
    max-width: 450px;
    justify-content: space-between;
  }
}
