.explainer,
.explainer > * {
  box-sizing: border-box;
}

.explainer {
  background-color: #532bbd;
  padding: 100px 0;
  text-align: left;
}
.expalainer-container {
  margin: 0 -15px;
}
.explainer-title {
  font-family: FatFrank;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  color: #99e580;
  padding: 0 15px 30px 15px;
}
.explainer-paragraphs-container {
  display: flex;
  padding: 0 0 30px 0;
}
.explainer-paragraph-small {
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 15px;
}
.explainer-paragraph-big {
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 15px;
}

@media (max-width: 920px) {
  .explainer-paragraphs-container {
    flex-wrap: wrap;
  }

  .explainer-paragraph-small {
    width: 45%;
  }
}

@media (max-width: 640px) {
  .explainer-paragraph-small {
    width: 100%;
  }
}
