.button {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: FatFrank;
  color: #532bbd;
  background: #99e580;
  padding: 16px 26px;
  font-size: 20px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  margin: 20px 0;
  text-transform: uppercase;
  border: 2px solid #532bbd;
  box-sizing: border-box;
  box-shadow: 10px 10px 0px #532bbd;
  border-radius: 6px;
}
.button__small {
  padding: 6px 36px;
}
.button:hover {
  box-shadow: none;
  top: 10px;
  left: 10px;
  position: relative;
}
.button-green {
  color: #532bbd;
  background: #99e580;
  border: 2px solid #532bbd;
  box-shadow: 10px 10px 0px #532bbd;
}
.button-purple {
  color: #99e580;
  background: #532bbd;
  border: 2px solid #99e580;
  box-shadow: 10px 10px 0px #99e580;
}
.button-white {
  color: #532bbd;
  background: #fff;
  border: 2px solid #532bbd;
  box-shadow: 10px 10px 0px #532bbd;
}
