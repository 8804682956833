.contact {
  display: flex;
  align-items: center;
  margin: 4rem 0 5rem 0;
  padding: 3rem 0;
  justify-content: space-between;
}

.contact > div {
  width: 45%;
}

.contact-description {
  text-align: left;
}

@media screen and (max-width: 920px) {
  .contact {
    flex-direction: column;
    margin: 0;
  }

  .contact > div {
    width: 100%;
  }
}
.contact .brands-images {
  margin-top: 4rem;
}
