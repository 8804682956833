.testimonials > * {
  box-sizing: border-box;
}

.testimonials .content {
  padding: 0;
}

.testimonials-container {
  margin: 80px 0;
}

.testimonials-container {
  display: flex;
  overflow-x: auto;
  padding: 0 0 5px 0;
}

.testimonials-container::after {
  /* This fixes an iPhone device issue: */
  /* right box-shadow of last card was being cut */
  content: "";
  flex-shrink: 0;
  width: 5px;
  height: 1px;
}

.testimonials-card {
  width: 250px;
  flex-shrink: 0;
  border: 1px solid #532bbd;
  border-radius: 6px;
  box-shadow: 5px 5px 0px #532bbd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.testimonials-card + .testimonials-card {
  margin: 0 0 0 2rem;
}

.testimonials-card:first-child {
  margin-left: 1.5rem;
}

.testimonials-card:last-child {
  margin-right: 1.5rem;
}

.testimonials-card-picture {
  margin: 1rem 0 0 0;
}

.testimonials-card-picture img {
  width: 91px;
  height: 91px;
}

.testimonials-card-text {
  width: 90%;
  font-size: 22px;
  line-height: 30px;
  color: #1e1e1c;
  margin: 0 0 25px 0;
}

.testimonials-card-name {
  color: #7c8087;
  font-size: 18px;
  margin: 0 0 25px 0;
}

@media (min-width: 920px) {
  .testimonials-container {
    margin: 120px 0;
  }

  .testimonials-card {
    width: 350px;
  }

  .testimonials-card:first-child {
    margin-left: 75px;
  }

  .testimonials-card:last-child {
    margin-right: 75px;
  }

  .testimonials-card-picture img {
    width: 125px;
    height: 125px;
  }
}
