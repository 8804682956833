.hero,
.hero > * {
  box-sizing: border-box;
}

.hero {
  width: 100%;
  /* height: 780px; */
}
.hero-image-container {
  padding: 80px;
  background-color: #99e580;
}

.hero-image {
  max-width: 1280px;
  height: 645px;
  margin: 0 auto;
  border-radius: 6px;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
    url("./../../images/hero.png"); */
  background-image: url("./../../images/hero.jpg");

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero-quote {
  position: absolute;
  left: -40px;
  max-width: 320px;
  text-align: left;
  /* Temporarily disabled */
  display: none;
}
.hero-overlay {
  background: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-125%, -50%);
  padding: 40px 4% 30px 4%;
  box-shadow: 5px 5px 0px #532bbd;
  border-radius: 6px;
  /* margin: 0 10%; */
  /* max-width: 410px; */
  /* width: auto; */
  /* width: 410px; */
  min-width: 280px;
}
.hero-titleimage {
  width: 168px;
  height: 100%;
  margin: 0 auto;
}
.hero-description {
  font-size: 18px;
  color: #000;
  padding: 10px 0 10px 0;
}
.hero-wrapper {
  max-width: 280px;
  margin: 0 auto;
  /* padding: 0 50px; */
}
.hero-explain {
  display: flex;
}

.hero-explain-textwrapper {
  display: inline-block;
  text-align: start;
  vertical-align: center;
  margin: auto 0;
  padding: 10px 0;
}
.hero-explain-heading {
  font-family: FatFrank;
  font-size: 20px;
  color: #532bbd;
  font-weight: bold;
  justify-content: flex-start;
  text-transform: uppercase;
}
.hero-explain-description {
  font-size: 12px;
  color: #1e1e1c;
  letter-spacing: 0.0168em;
  font-feature-settings: "tnum" on, "lnum" on;
}
.hero-button {
  display: inline-flex;
}
.hero-button a {
  text-decoration: none;
}

@media (max-width: 920px) {
  .hero-image-container {
    height: calc(100% - 80px);
    padding: 40px 0;
    background-color: #99e580;
  }

  .hero-quote {
    display: none;
  }

  .hero-overlay {
    width: calc(100% - 4rem);
    max-width: 350px;
    transform: translate(-50%, -50%);
  }
}
