.content-cookie-policy {
  max-width: 800px;
}

.cookie-table-container {
  overflow-x: auto;
}

.cookie-table {
  border-collapse: collapse;
}

.cookie-table tr > * {
  border: 1px solid black;
  padding: 0.25rem 0.5rem;
  min-width: 8rem;
}
