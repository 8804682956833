.cookie-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 5;
}
.cookie-popup-container {
  border: 2px solid #532bbd;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #fff;
  max-width: 730px;

  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  margin-top: -150px;
  margin-left: -365px;
}
.cookie-popup-text {
  margin: 0 10px;
}
.cookie-popup-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  justify-content: space-evenly;
  padding: 0 0 15px 0;
}

@media screen and (max-width: 920px) {
  .cookie-popup-container {
    position: fixed;
    top: 10%;
    margin: auto 5%;
    left: 0;
  }
}
@media screen and (max-width: 640px) {
  .cookie-popup-buttons .button {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 17px;
    box-shadow: 7px 7px 0px #532bbd;
  }
}
