body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Caecilia";
  src: local("Caecilia"),
    url(./fonts/Caecilia\ LT\ Pro\ 55\ Roman.otf) format("truetype");
}
@font-face {
  font-family: "FatFrank";
  src: local("FatFrank"), url(./fonts/FatFrank.otf) format("truetype");
}
h1 {
  color: #532bbd;
  font-family: FatFrank;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
}
p {
  font-size: 16px;
  line-height: 30px;
  color: #1e1e1c;
}
