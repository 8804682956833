.App {
  text-align: center;
  background-color: #fff;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-container {
  margin: 0 10%;
}
.App-container a {
  text-decoration: none;
}
.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-question-list {
  padding-left: 0;
}

.App-link {
  color: #61dafb;
}
a {
  text-decoration: none;
  color: #272727;
}

.content {
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto 60px auto;
  padding: 0 75px;
}

.content-inst {
  text-align: left;
  max-width: 540px;
  padding: 3rem 0;
}

@media screen and (max-width: 920px) {
  .content {
    padding: 0 1.5rem;
  }

  .content-inst {
    padding: 3rem 1.5rem;
  }
}
