.introduction {
  height: auto;
  padding: 0 40px 60px 40px;
  background-color: #99e580;
  margin-bottom: 20px;
}
.introduction-hero {
  box-sizing: border-box;
  max-width: 1280px;
  margin: 0 auto;
  height: 266px;
  display: flex;
  flex-direction: column;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),
    url("./../../images/hero.png"); */
  /* background-image: url("./../../images/hero.png"); */
  background-image: url("./../../images/hero.jpg");
  border-bottom-left-radius: 6px;
  /* background-position: center center; */
  background-position: 50% 30%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.introduction-description {
  text-align: left;
  font-size: 18px;
  /* margin: 10px 0 10px 0; */
  color: #1e1e1c;
  font-size: 16px;
  line-height: 30px;
}
.introduction-description a {
  color: #532bbd;
  text-decoration: underline;
}
.question {
  list-style-type: none;
  width: 100%;
  color: #272727;
  margin: 40px 0;
}
.question-number {
  font-family: FatFrank;
  font-size: 20px;
  line-height: 24px;
  color: #532bbd;
  /* display: flex; */
  text-align: left;
  /* flex-direction: start; */
  margin: 60px 0 20px 0;
  text-transform: uppercase;
  font-weight: 900;
}
.question-number span {
  text-transform: none;
  color: red;
  font-size: 12px;
  margin: 0 0 0 0.5rem;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.question-container {
  display: flex;
}
.question-text {
  font-style: normal;
  font-size: 26px;
  line-height: 30px;
  color: #1e1e1c;
  text-align: start;
  line-height: 35px;
}
.question-tooltip {
  padding-left: 20px;
  margin: auto 0;
}
.answer-container {
  width: fit-content;
}
.answer {
  background-color: #f2f2f2;
  border-radius: 4px;
  color: #272727;
  margin: 20px 0 20px 0;
  padding: 8px 0 8px 0;
  display: flex;
  align-items: center; /* Vertical center alignment */
  justify-content: flex-start; /* Horizontal center alignment */
  text-align: left;
  font-size: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  /* TEMP */
  width: 100%;
}
@media (hover: hover) {
  .answer:not(.answer__selected):hover,
  .answer:active {
    background-color: #e0e0e0;
  }
}
.answer__selected {
  background-color: #532bbd;
  color: #fff;
  font-weight: 700;
}

.answer span {
  padding: 0 44px 0 44px;
  display: inline-flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}
.answer span::after {
  content: attr(data-text);
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
  font-weight: 900;
}

.result-button {
  margin: 65px 0 85px 0;
}
.result-button-inactive {
  margin: 65px 0 85px 0;
}
.result-button-inactive .button {
  color: #bdbdbd;
  background: #f2f2f2;

  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  box-shadow: none;
}
.results-notfound {
  margin-top: 80px;
  margin-right: 80px;
}
.results-notfound-text {
  display: inline-block;
  max-width: 400px;
}
@media (max-width: 920px) {
  .introduction {
    width: 100%;
    padding: 0 0 60px 0;
  }

  .introduction-hero {
    height: 400px;
    border-bottom-left-radius: 0;
    /* background-image: url("./../../images/hero.png"); */
    background-image: url("./../../images/hero.jpg");
  }

  .introduction-header {
    display: none;
  }

  .introductioncard-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(0px);
    padding-top: 4rem;
  }

  .introductioncard {
    margin-top: 2rem;
    height: auto;
  }
}
