.research,
.research > * {
  box-sizing: border-box;
}

.research {
  background-color: #532bbd;
  padding: 80px 0;
  text-align: left;
  width: 100%;
}

.research-image-desktop {
  display: none;
}

.research-image-desktop img {
  width: 100%;
}

.research-image-mobile {
  margin: 2rem 0 0 0;
  text-align: center;
}

.research-image-mobile img {
  width: 100%;
}

.research-title {
  font-family: FatFrank;
  font-size: 40px;
  line-height: 48px;
  color: #99e580;
  font-weight: bold;
  text-transform: uppercase;
}

.research-text {
  font-size: 16px;
  line-height: 30px;
  margin: 2rem 0 0 0;
  color: white;
}

.research-button {
  margin: 2rem 0 0 0;
}

@media (min-width: 768px) {
  .research-image-desktop {
    display: block;
  }

  .research-image-mobile {
    display: none;
  }

  .research .content {
    display: flex;
  }
}
