.results {
  text-align: left;
}
.results-description {
}

.popup,
.popup * {
  box-sizing: border-box;
}
.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 5;
  top: 0;
}
.popup-container {
  border: 2px solid #532bbd;
  border-radius: 6px;
  background-color: #fff;
  max-width: 730px;
  padding: 70px 60px 0 60px;

  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  margin-top: -150px;
  margin-left: -365px;
}

.popup-container h1 {
  margin: 0;
}

.popup-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  justify-content: space-evenly;
  padding: 0 0 15px 0;
}
@media screen and (max-width: 920px) {
  .popup-container {
    position: fixed;
    top: 10%;
    margin: auto 5%;
    left: 0;
    padding: 40px 20px 0 20px;
  }
}
