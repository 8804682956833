.footer {
  width: 100%;
  min-height: 84px;
  background: #532bbd;
  padding: 60px 0 0 0;
}
.footer-container {
  max-width: 1110px;
  margin: 0 auto;
}
.footer-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.footer-link {
  padding: 32px 0 32px 0;
  color: #ffffff;
  min-width: 100px;
  text-align: left;
}
.footer-link a {
  color: #ffffff;
  padding: 0 40px 0 40px;
  /* margin: 0 40px; */
  /* text-align: left; */
}
.copyright {
  background-color: #e5e5e5;
  width: 100%;
  height: 76px;
}
.copyright-container {
  margin: auto 5%;
  color: #532bbd;
  text-align: left;
  font-size: 16px;
  line-height: 28px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
@media screen and (max-width: 920px) {
  .footer-container {
    margin: 0 1.5rem;
  }

  .footer-flex {
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px 0 37px 0;
  }

  .footer-link {
    padding: 10px 0 10px 0;
  }
  .footer-link a {
    padding: 0 0 0 0;
    width: 100%;
  }
}
